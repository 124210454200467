<template>
  <page-structure title="portfolio" :loading="loading"
    ><pictures-grid v-if="pictures.length > 0" :pictures="pictures" />
  </page-structure>
  <!-- <LightBoxImg id="LightBoxImg" ref="LightBox" @open="onOpen" /> -->
</template>

<script>
import PicturesGrid from "@/components/portfolio/PicturesGrid.vue";

export default {
  name: "portfolio",
  data: () => {
    return {
      loading: false,
      pictures: [],
    };
  },
  async mounted() {
    this.$emit("setsocial", true);
    try {
      const pictures = await this.$store.dispatch("fetchPictures");
      this.pictures = pictures;
      this.loading = false;
    } catch (err) {
      console.error(err);
    }
  },
  components: { PicturesGrid },
  metaInfo: {
    title: "Porfolio",
    titleTemplate: "%s - moldoVAN",
    htmlAttrs: {
      lang: "en",
    },
  },
  methods: {},
};
</script>

<style lang="scss">
@import "@/assets/css/portfolioPage";
</style>
