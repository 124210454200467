<template>
  <div class="row" v-if="pictures.length > 0">
    <div
      class="animate-box fadeInUp animated portfolio-grid"
      data-animate-effect="fadeInUp"
    >
      <stack
        :column-min-width="270"
        :gutter-width="20"
        :gutter-height="20"
        monitor-images-loaded
      >
        <stack-item v-for="(picture, i) in items" :key="i">
          <picture-grid-item :key="picture.id" :picture="picture">
          </picture-grid-item>
        </stack-item>
      </stack>
    </div>
    <div
      class="animate-box fadeInUp animated pagination-container"
      data-animate-effect="fadeInUp"
      v-if="pageCount > 1"
    >
      <b-pagination
        v-model="pageNumber"
        :total-rows="totalRows"
        :per-page="pageSize"
        first-number
        last-number
        prev-text="←"
        next-text="→"
        aria-controls="my-table"
        align="center"
        limit="5"
        @change="pageChangeHandler"
      ></b-pagination>
    </div>
  </div>
  <div v-else>No pictures yet</div>
</template>

<script>
import paginationMixin from "@/mixins/pagination.mixin";
export default {
  data: () => {
    return { pageSize: 24 };
  },
  mixins: [paginationMixin],
  props: {
    pictures: {
      type: Array,
      required: true,
    },
  },
  async mounted() {
    this.setUp(this.pictures);
  },
  methods: {
    // pageChangeHandler() {},
    setUp(pictures) {
      this.setUpPagination(pictures);
    },
  },
};
</script>
